@import "../../scss/variables";

.ResultsSubscaleTable {
  table {
    thead tr {
      th:nth-child(2),
      th:nth-child(3),
      th:nth-child(4) {
        width: 3rem;
      }
    }

    .section-header {
      background: $primary;
      @include over-primary-text-mixin;

      h5 {
        margin: 0;
      }
    }
  }
}
