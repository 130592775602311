.TestInfo {
  table {
    width: 100%;
    tr {
      td:first-child {
        text-align: right;
        padding-right: 0.25rem;
      }

      td:last-child {
        padding-left: 0.25rem;
      }
    }
  }
}
