.DevelopmentAreaDetails {
  .AnswerSplit {
    h5 {
      font-weight: 700;
    }

    td {
      width: 33%;
      vertical-align: middle;
    }

    tr:last-child {
      td {
        vertical-align: top;
      }
    }

    .answer-split-list {
      list-style-type: none;

      li {
        position: relative;
        padding-left: 0.5em;
        line-height: 1.25em;
        padding-bottom: 0.25em;

        &:last-child {
          padding-bottom: 0;
        }

        .question-id {
          font-weight: 700;

          position: absolute;
          width: 100%;
          height: 100%;
          right: 100%;

          display: flex;
          justify-content: flex-end;
          align-items: flex-start;
          pointer-events: none;
        }
      }
    }
  }
}
