.Form {
  .content {
    .container {
      margin-bottom: 1rem;
    }
  }

  .buttons {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;

    margin-top: 1rem;
    text-align: center;
  }
}
