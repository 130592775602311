@import "~bootstrap/scss/bootstrap";

$button-size: 40px;

.Value012Field {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 3 * $button-size;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  .form-control {
    padding: 0.375rem;
    text-align: center;
    font-weight: 700;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: $button-size;
    margin-left: $button-size;
    font-size: 1.25rem;

    &:focus {
      box-shadow: none;
      border-width: 4px;
      border-color: $orange;
    }
  }

  button.btn {
    font-size: 1.4rem;
    width: $button-size;
    height: $button-size;
    padding: 0;

    //&:first-child {
    //  border-top-left-radius: 0;
    //}

    //&:last-child {
    //  border-top-right-radius: 0;
    //}
  }

  &.nd {
    width: 4 * $button-size;

    .form-control {
      margin-left: 1.5 * $button-size;
      padding: 0.1rem;
    }
  }
}
