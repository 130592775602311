@import "../../scss/variables";

.ResultsTable {
  .container {
    overflow-x: auto;
  }

  table {
    th {
      padding: 0.75rem 1rem;

      &.scale {
        min-width: 3em;
      }
    }

    td.main {
      font-family: $font-family-header;
      font-size: 1.1em;
      background: $primary;
      @include over-primary-text-mixin;
    }

    td.empty:before {
      content: "—";
    }
  }
}
