.Section {
  .section-header {
    margin-bottom: 2rem;
  }

  .section-element {
    margin-bottom: 1rem;

    .label {
      display: flex;
      align-items: center;
    }

    .react-datepicker-wrapper {
      width: 100%;

      input {
        width: 100%;

        padding: 0.375rem 0.75rem;
        height: calc(1.5em + 0.75rem + 2px);
        line-height: 1.5;
        color: #495057;
        border: 1px solid #ced4da;
        border-radius: 0.25rem;
      }
    }
  }
}
